// const baseUrl = "http://1459-58-136-0-112.ngrok.io";
// NOTE Development url
const baseUrl = "https://api-elearning.houseofdev.tech/dev/";

// NOTE UAT url
// const baseUrl = "https://api.fastation.co.th/uat/";

// NOTE Production url
// const baseUrl = "https://api.fastation.co.th/prod/";
export default baseUrl;

// const stage = () => {
//   const url = window.location.hostname;
//   switch (url) {
//     case "dddd":
//       return "production";
//     case "staging-fa-station.web.app":
//     case "staging-fa-station.firebaseapp.com":
//     case "staging.fastation.co.th":
//       return "uat";
//     case "fa-station.web.app":
//     case "fa-station.firebaseapp.com":
//     case "dev.fastation.co.th":
//     case "localhost":
//       return "dev";
//     default:
//       return null;
//   }
// };

// const baseUrl = () => {
//   switch (stage()) {
//     case "production":
//       return "https://api.fastation.co.th/prod";
//     case "uat":
//       return "https://api.fastation.co.th/uat";
//     case "dev":
//       return "https://api.fastation.co.th/dev";
//     case "local":
//       return "http://localhost:3000/";
//     default:
//       return "http://localhost:3000/";
//   }
// };

// export default baseUrl();
