import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () => import("../layout/Main.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/lesson/:courseId",
        name: "Lesson",
        component: () => import("../views/Lesson.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/curriculum",
        name: "Curriculum",
        component: () => import("../views/Curriculum.vue"),
      },
      {
        path: "/curriculum/:curriculumId",
        name: "CurriculumDetail",
        component: () => import("../views/Courses.vue"),
      },
      {
        path: "/detail/:courseId",
        name: "Detail",
        component: () => import("../views/CourseDetail.vue"),
      },
      {
        path: "/payment/:courseId",
        name: "Payment",
        component: () => import("../views/Payment.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/register",
        name: "Register",
        component: () => import("../views/Register.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/about",
        name: "About",
        component: () => import("../views/About.vue"),
      },
      {
        path: "/terms",
        name: "Terms",
        component: () => import("../views/policy/Terms.vue"),
      },
      {
        path: "/privacy",
        name: "Privacy",
        component: () => import("../views/policy/Privacy.vue"),
      },
      {
        path: "/deletion",
        name: "Deletion",
        component: () => import("../views/policy/Deletion.vue"),
      },
      {
        path: "/forget",
        name: "Forget",
        component: () => import("../views/ForgetPassword.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/resetpassword",
        name: "ResetPassword",
        component: () => import("../views/ResetPassword.vue"),
        meta: { requiresUnAuth: true },
      },
    ],
  },
  {
    path: "",
    component: () => import("../layout/Profile.vue"),
    children: [
      {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/profile/Profile.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mycourse",
        name: "MyCourse",
        component: () => import("../views/profile/MyCourse.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/suggestion",
        name: "Suggestion",
        component: () => import("../views/profile/Suggestion.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/paymenthistory",
        name: "PayHistory",
        component: () => import("../views/profile/PayHistory.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "*",
    component: () => import("../views/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // },
  routes,
});

const checkDomain = () => {
  if (
    window.location.hostname == "fa-station.web.app" ||
    window.location.hostname == "fa-station.firebaseapp.com"
  ) {
    window.location.replace("https://dev.fastation.co.th");
  }
  if (
    window.location.hostname == "staging-fa-station.web.app" ||
    window.location.hostname == "staging-fa-station.firebaseapp.com"
  ) {
    window.location.replace("https://staging.fastation.co.th");
  }
};

router.beforeEach(async (to, from, next) => {
  checkDomain();
  if (localStorage.getItem("token") && !store.getters.isAuth) {
    try {
      await store.dispatch("autoLogin");
      if (to.meta.requiresUnAuth && store.getters.isAuth) {
        next({ name: "Home" });
      } else {
        next();
      }
    } catch (err) {
      alert(err);
      next({ name: "Home" });
    }
  } else {
    // insert logic to lock path from meta here //
    if (to.meta.requiresUnAuth && store.getters.isAuth) {
      next(false);
    } else if (to.meta.requiresAuth && !store.getters.isAuth) {
      next({ name: "Login" });
    } else {
      next();
    }
  }
});
router.afterEach((to, from) => {
  if (to.path !== from.path) {
    window.scrollTo(0, 0);
  }
});

export default router;
